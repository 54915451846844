// 时间戳转化为日期时分秒 以 / 隔开
const formatTime1 = date => {
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hour = date.getHours()
    const minute = date.getMinutes()
    const second = date.getSeconds()
    return [year, month, day].map(formatNumber).join('/') + ' ' + [hour, minute, second].map(formatNumber).join(':')
  }
  
  // 时间戳转化为日期 以 - 隔开
  const formatTime2 = date => {
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    return [year, month, day].map(formatNumber).join('-')
  }
  
  // 时间戳转化为日期时分秒 以 - 隔开
  const formatTime3 = date => {
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hour = date.getHours()
    const minute = date.getMinutes()
    const second = date.getSeconds()
    return [year, month, day].map(formatNumber).join('-') + ' ' + [hour, minute, second].map(formatNumber).join(':')
  }
  
  // 毫秒转年月日
  const formatTime4 = date => {
    var date = new Date(date)
    var y = date.getFullYear()
    var m = date.getMonth() + 1
    if(m<10){
      m="0"+m;
    }
    var d = date.getDate()
    if(d<10){
      d="0"+d;
    }
    return y + '-' + m + '-' + d
  }
  
  // 获取 月 日
  const formatTime5 = date => {
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    return month + '月' + day + '日'
  }
  
  // 时间戳转化为日期时分 以 - 隔开
  const formatTime6 = date => {
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hour = date.getHours()
    const minute = date.getMinutes()
    return [year, month, day].map(formatNumber).join('-') + ' ' + [hour, minute].map(formatNumber).join(':')
  }
  
  // 分钟转为秒
  const secToMin = s => {
    var h
    h = Math.floor(s / 60)
    s = s % 60
    h += ''
    s += ''
    h = (h.length == 1) ? '0' + h : h
    s = (s.length == 1) ? '0' + s : s
    return h + ':' + s
  }
  
  // 时分转化为毫秒
  const timeToSec = time => {
    var hour = time.split(':')[0]
    var min = time.split(':')[1]
    var s = Number(hour * 3600) + Number(min * 60)
    return s * 1000
  }
  
  // 毫秒转化为时分秒
  const secToTime = mss => {
    var days = parseInt(mss / (1000 * 60 * 60 * 24));
    var hours = parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.round((mss % (1000 * 60)) / 1000);
    return days + "天 " + hours + "时 " + minutes + "分 " + seconds + "秒";
  }
  
  // 毫秒转化为时分秒
  const secToTime2 = mss => {
    var hours = parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.round((mss % (1000 * 60)) / 1000);
    var hours2 = hours < 10 ? '0'+hours : hours
    var minutes2 = minutes < 10 ? '0'+minutes : minutes
    var seconds2 = seconds < 10 ? '0'+seconds : seconds
    return hours2 + ":" + minutes2 + ":" + seconds2
  }
  
  // 将数组按照指定规则分割成几组数组
  const group = (array, subGroupLength) => {
    let index = 0
    let newArray = []
    while(index < array.length) {
      newArray.push(array.slice(index, index += subGroupLength))
    }
    return newArray
  }
  
  // 判断两个日期大小
  const compareDate = (checkStartDate, checkEndDate) => {     
    var arys1 = new Array()  
    var arys2 = new Array()     
    if(checkStartDate != null && checkEndDate != null) {     
      arys1 = checkStartDate.split('-');     
      var sdate = new Date(arys1[0],parseInt(arys1[1]-1),arys1[2]);     
      arys2 = checkEndDate.split('-');     
      var edate = new Date(arys2[0],parseInt(arys2[1]-1),arys2[2]);     
      if(sdate <= edate) {  
        return true        
      }
      else {  
        return false
      }  
    }     
  }
  
  // 数字转化为字符串
  const formatNumber = n => {
    n = n.toString()
    return n[1] ? n : '0' + n
  }
  
  // 百度转腾讯
  const bdMap_to_txMap = (lat,lng) => {
    let x_pi = 3.14159265358979324 * 3000.0 / 180.0;
    let x = lng - 0.0065;
    let y = lat - 0.006;
    let z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_pi);
    let theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi);
    let lngs = z * Math.cos(theta);
    let lats = z * Math.sin(theta);
    return {
      lng: lngs,
      lat: lats        
    }   
  }
  
  // 秒转分秒
  const formatSeconds = value => {
    var theTime = parseInt(value), middle = 0, result = ''
    if(theTime > 60) {
      middle = parseInt(theTime / 60);
      theTime = parseInt(theTime % 60);
    }
    if(parseInt(theTime) >= 10){
      result = "0" + ":" + parseInt(theTime);
    }else{
      result = "0" + ":" + "0" + parseInt(theTime);
    }
    if(middle >= 0 && parseInt(theTime) >= 10) {
      if(parseInt(middle) >= 10){
        result = parseInt(middle) + ":" + parseInt(theTime);
      }else{
        result = "0" + parseInt(middle) + ":" + parseInt(theTime);
      }
    }else{
      if(parseInt(middle) >= 10){
        result = parseInt(middle) + ":" + "0" + parseInt(theTime);
      }else{
        result = "0" + parseInt(middle) + ":" + "0" + parseInt(theTime);
      }
    }
    return result;
  }
  
  // 获取url参数
  const filterUrlParam = (urlSearch) => {
    let ret = {}
    let regParam = /([^&=]+)=([\w\W]*?)(&|$|#)/g
    if (urlSearch) {
      var strParam = urlSearch;
      var result
      while ((result = regParam.exec(strParam)) != null) {
        ret[result[1]] = result[2]
      }
    }
    return ret
  }
  
  // 判断是否为空
  const trim = (str) => {
    if(str == null || typeof str == "undefined"){
      return ""
    }
    return str.replace(/(^\s*)|(\s*$)/g, "")
  }
  
  // 姓名脱敏
  const desensitization = (name) => {
    if(name.length==2){
      return name.substring(0,1)+'*'
    }else if(name.length==3){
      return name.substring(0,1)+"*"+name.substring(2,3)
    }else if(name.length>3){
      return name.substring(0,1)+"*"+'*'+name.substring(3,name.length)
    }	
  }
  
  // 生成随机字母
  const randomStr = (len, arr) => {
    var ans = '';
    for(var i = len; i > 0; i--) {
      ans +=
      arr[Math.floor(Math.random() * arr.length)];
    }
    return ans;
  }
  
  // 数组根据某个字段去重
  const arrayUnique = (arr, name) => {
    var hash = {};
    return arr.reduce(function (item, next) {
      hash[next[name]] ? '' : hash[next[name]] = true && item.push(next);
      return item;
    }, [])
  }
  
  module.exports = {
    formatTime1: formatTime1,
    formatTime2: formatTime2,
    formatTime3: formatTime3,
    formatTime4: formatTime4,
    formatTime5: formatTime5,
    formatTime6: formatTime6,
    timeToSec: timeToSec,
    secToTime: secToTime,
    group: group,
    compareDate: compareDate,
    bdMapToTxMap: bdMap_to_txMap,
    formatSeconds: formatSeconds,
    secToMin: secToMin,
    filterUrlParam: filterUrlParam,
    trim: trim,
    desensitization: desensitization,
    secToTime2: secToTime2,
    randomStr: randomStr,
    arrayUnique: arrayUnique
  
  }